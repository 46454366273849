.tinyDivider {
    height: 100%;
    width: 1px;
}

.tinyDivider__white {
    background-color: white;
    opacity: 0.2;
}

.tinyDivider__primary {
    background-color: var(--col-primary);
}